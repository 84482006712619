.IFramePanel {
    display: flex;
    position: fixed;
    flex-direction: column;
    left: 0;
    top: 0;
    margin: 8px;
    z-index: 11;
    max-height: 40vh;
}

.MuiCard-root{
    overflow-y: auto !important ;
}

@media screen and (max-width: 600px) {
    .IFramePanel {
        max-height: 25vh;
    }
}