.firmlogo {
  position: fixed;
  right: 0;
  bottom: 0;
  max-width: 100px;
  min-width: 60px;
  padding: 1rem;
  opacity: 0.5;
  width: 8%;
  transition: 0.4s;

  &:hover {
    opacity: 0.99;
  }
}

.ankageo-logo {
  position: fixed;
  bottom: 30px;
  left: 0;
  z-index: 1;
  img {
    width: 125px;
    height: 28px;
  }
  .logo-1 {
    position: absolute;
    z-index: 1;
  }
  .logo-2 {
    position: absolute;
    opacity: 0;
    z-index: 2;
    transition: 0.4s;
  }

  &:hover {
    .logo-2 {
      opacity: 1;
    }
  }
}