.IFrameTileset{
    // margin: 2px 8px 2px 2px;
    margin: 0px -2px 0px 10px;
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    grid-gap: 0.5rem;

    .search-button {
        cursor: pointer;
    }
    .MuiButtonBase-root  {
        padding: 4px !important;
    }
}